var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { title: "弹出子表示例", bordered: false } },
    [
      _c("j-vxe-table", {
        attrs: {
          toolbar: "",
          "row-number": "",
          "row-selection": "",
          "highlight-current-row": "",
          "click-row-show-sub-form": "",
          "click-row-show-main-form": "",
          height: 750,
          loading: _vm.loading,
          columns: _vm.columns,
          dataSource: _vm.dataSource,
        },
        on: { detailsConfirm: _vm.handleDetailsConfirm },
        scopedSlots: _vm._u([
          {
            key: "mainForm",
            fn: function ({ row }) {
              return [
                row
                  ? [
                      _c(
                        "a-form-model",
                        {
                          ref: "form2",
                          attrs: {
                            model: row,
                            rules: _vm.rules,
                            "label-col": _vm.labelCol,
                            "wrapper-col": _vm.wrapperCol,
                          },
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: 8 } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "ID", prop: "id" } },
                                    [
                                      _c("a-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: row.id,
                                          callback: function ($$v) {
                                            _vm.$set(row, "id", $$v)
                                          },
                                          expression: "row.id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "序号", prop: "num" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.num,
                                          callback: function ($$v) {
                                            _vm.$set(row, "num", $$v)
                                          },
                                          expression: "row.num",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "船名",
                                        prop: "ship_name",
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.ship_name,
                                          callback: function ($$v) {
                                            _vm.$set(row, "ship_name", $$v)
                                          },
                                          expression: "row.ship_name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "呼叫", prop: "call" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.call,
                                          callback: function ($$v) {
                                            _vm.$set(row, "call", $$v)
                                          },
                                          expression: "row.call",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "长", prop: "len" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.len,
                                          callback: function ($$v) {
                                            _vm.$set(row, "len", $$v)
                                          },
                                          expression: "row.len",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "吨", prop: "ton" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.ton,
                                          callback: function ($$v) {
                                            _vm.$set(row, "ton", $$v)
                                          },
                                          expression: "row.ton",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: { label: "付款方", prop: "payer" },
                                    },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.payer,
                                          callback: function ($$v) {
                                            _vm.$set(row, "payer", $$v)
                                          },
                                          expression: "row.payer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "数", prop: "count" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.count,
                                          callback: function ($$v) {
                                            _vm.$set(row, "count", $$v)
                                          },
                                          expression: "row.count",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: { label: "公司", prop: "company" },
                                    },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.company,
                                          callback: function ($$v) {
                                            _vm.$set(row, "company", $$v)
                                          },
                                          expression: "row.company",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "动向", prop: "trend" } },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value: row.trend,
                                          callback: function ($$v) {
                                            _vm.$set(row, "trend", $$v)
                                          },
                                          expression: "row.trend",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "subForm",
            fn: function ({ row }) {
              return [
                _vm.loadSubData(row)
                  ? [
                      _c("j-vxe-table", {
                        ref: "subFormTable",
                        attrs: {
                          height: "auto",
                          "max-height": 350,
                          loading: _vm.subTable.loading,
                          columns: _vm.subTable.columns,
                          dataSource: _vm.subTable.dataSource,
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }